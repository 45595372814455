<template>
  <div>
    <v-container fluid>
      <div class="text-subtitle ma-2 text-center font-weight-light">
        {{ $vuetify.lang.t("$vuetify.home.recentQrCode", title) }}
        <div class="text-caption font-weight-light">
          ({{ totalSizes }} found)
        </div>
      </div>
      <v-text-field
        dense
        v-model="searchTerms"
        @keyup.enter="search"
        @click:clear="clearSearch"
        @click:append="search"
        append-icon="mdi-magnify"
        outlined
        clearable
        :label="$vuetify.lang.t('$vuetify.base.search') +' '+ $vuetify.lang.t('$vuetify.base.qrCode')"
      />
      <infinite-scroll
        :key="infiniteScrollKey"
        @fetchData="fetchData"
        :totalPages.sync="totalPages"
      >
        <v-row align="center" justify="center">
          <v-col
            cols="12"
            md="6"
            lg="4"
            v-for="qrCode in qrCodes"
            :key="qrCode.id"
          >
            <v-sheet
              @click="dispatchQrCode(qrCode)"
              height="100%"
              v-ripple
              :elevation="2"
              class="ma-2 pa-5"
            >
              <div style="float: left" class="text-caption font-weight-light">
                {{ qrCode.id }}
              </div>
              <worksheet-qr-code v-model="qrCode.url" :qrCode="qrCode" />
            </v-sheet>
          </v-col>
        </v-row>
      </infinite-scroll>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const WorksheetQrCode = () => import("@/components/qrCode/WorksheetQRCode.vue");

export default {
  data() {
    return {
      qrCodes: [],
      infiniteScrollKey: Date.now(),
      totalPages: null,
      totalSizes: null,
      searchTerms: null
    };
  },
  mounted() {},
  components: {
    WorksheetQrCode,
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("dynamicQrCode", ["fetchMyScannedQrCodes"]),

    dispatchQrCode(qrCode) {
      if (qrCode) {
        this.$router.push({
          name: "QrDispatcher",
          params: { uuid: qrCode.uuid },
        });
      }
    },
    fetchData({ page, done, empty }) {
      const params = {
        page,
        size: 5,
        sort: "id,desc",
        searchTerms: this.searchTerms
      };
      this.fetchMyScannedQrCodes(params).then((resp) => {
        const newPage = resp.content;
        this.qrCodes = this.qrCodes.concat(newPage);
        this.totalPages = resp.totalPages;
        this.totalSizes = resp.totalElements;
        if (this.totalSizes > 0) {
          done();
        } else {
          empty();
        }
      });
    },
    search() {
      this.qrCodes = []
      this.infiniteScrollKey = Date.now();
    },
    clearSearch() {
      this.qrCodes = []
      this.searchTerms = null;
      this.infiniteScrollKey = Date.now();
    },
  },
};
</script>

<style></style>
